import {authConstants} from '../constants';

let auth = JSON.parse(localStorage.getItem('auth'));
const initialState = auth ? {loggedIn: true, auth} : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                auth: action.auth
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                auth: action.auth
            };
        case authConstants.LOGIN_FAILURE:
            return {
                error: true,
            };
        case authConstants.LOGOUT:
            return {};
        default:
            return state
    }
}