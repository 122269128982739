import {combineReducers} from 'redux';

import {authentication} from './authReducer';
import {alert} from './alertReducer';
import {sidebar} from './sidebarReducer';
import {reservation} from './reservationReducer';
import {reservations} from './reservationsReducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    sidebar,
    reservation,
    reservations,
});

export default rootReducer;