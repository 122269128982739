import {authConstants} from '../constants';
import {authService} from '../services';
import {alertActions} from './';
import {history} from '../helpers';

export const authActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));

        authService.login(username, password)
            .then(
                auth => {
                    dispatch(success(auth));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(auth) {
        return {type: authConstants.LOGIN_REQUEST, auth}
    }

    function success(auth) {
        return {type: authConstants.LOGIN_SUCCESS, auth}
    }

    function failure(error) {
        return {type: authConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    authService.logout();
    return {type: authConstants.LOGOUT};
}