import {reservationConstants} from '../constants';

const initialState = {
    loading: false,
    reservation: null,
    conflicts: null,
    created: null,
    edited: null,
    removed: false,
    error: null,
};

export function reservation(state = initialState, action) {
    switch (action.type) {
        case reservationConstants.RESERVATIONS_GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationConstants.RESERVATIONS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                reservation: action.reservation,
                conflicts: action.conflicts,
            };
        case reservationConstants.RESERVATIONS_GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case reservationConstants.RESERVATIONS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationConstants.RESERVATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                reservation: action.reservation
            };
        case reservationConstants.RESERVATIONS_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case reservationConstants.RESERVATIONS_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationConstants.RESERVATIONS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                created: action.reservation
            };
        case reservationConstants.RESERVATIONS_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case reservationConstants.RESERVATIONS_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationConstants.RESERVATIONS_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                edited: action.reservation
            };
        case reservationConstants.RESERVATIONS_EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case reservationConstants.RESERVATIONS_REMOVE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationConstants.RESERVATIONS_REMOVE_SUCCESS:
            return {
                ...state,
                loading: false,
                removed: true
            };
        case reservationConstants.RESERVATIONS_REMOVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}