import config from '../config.json';

export const authService = {
    login,
    logout
};

function login(login, password) {
    const requestOptions = {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({login, password})
    };
    return fetch(`${config.apiUrl}/v1/auth/login`, requestOptions)
        .then(handleResponse)
        .then(auth => {
            localStorage.setItem('auth', JSON.stringify(auth));
            return auth;
        })
}

function logout() {
    localStorage.removeItem('auth');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}