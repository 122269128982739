import {store} from './store'
import {authActions} from '../actions'

export function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(authActions.logout());
                window.location.reload(true);
            }

            const error = text || response.statusText;
            return Promise.reject(error);
        }

        return text && JSON.parse(text);
    });
}