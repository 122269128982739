export const reservationConstants = {
    RESERVATIONS_GET_REQUEST: 'RESERVATIONS_GET_REQUEST',
    RESERVATIONS_GET_SUCCESS: 'RESERVATIONS_GET_SUCCESS',
    RESERVATIONS_GET_FAILURE: 'RESERVATIONS_GET_FAILURE',

    RESERVATIONS_UPDATE_REQUEST: 'RESERVATIONS_UPDATE_REQUEST',
    RESERVATIONS_UPDATE_SUCCESS: 'RESERVATIONS_UPDATE_SUCCESS',
    RESERVATIONS_UPDATE_FAILURE: 'RESERVATIONS_UPDATE_FAILURE',

    RESERVATIONS_CREATE_REQUEST: 'RESERVATIONS_CREATE_REQUEST',
    RESERVATIONS_CREATE_SUCCESS: 'RESERVATIONS_CREATE_SUCCESS',
    RESERVATIONS_CREATE_FAILURE: 'RESERVATIONS_CREATE_FAILURE',

    RESERVATIONS_EDIT_REQUEST: 'RESERVATIONS_EDIT_REQUEST',
    RESERVATIONS_EDIT_SUCCESS: 'RESERVATIONS_EDIT_SUCCESS',
    RESERVATIONS_EDIT_FAILURE: 'RESERVATIONS_EDIT_FAILURE',

    RESERVATIONS_REMOVE_REQUEST: 'RESERVATIONS_REMOVE_REQUEST',
    RESERVATIONS_REMOVE_SUCCESS: 'RESERVATIONS_REMOVE_SUCCESS',
    RESERVATIONS_REMOVE_FAILURE: 'RESERVATIONS_REMOVE_FAILURE',
};
