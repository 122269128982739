import {reservationsConstants} from '../constants';
import {reservationsService} from '../services';
import {alertActions} from './';

export const reservationsActions = {
    get,
};

function get(status, payment, from, to) {
    return dispatch => {
        dispatch(request());
        reservationsService.getAll(status, payment, from, to)
            .then(data => {
                dispatch(success(data));
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationsConstants.RESERVATIONS_GET_REQUEST}
    }

    function success(reservations) {
        return {type: reservationsConstants.RESERVATIONS_GET_SUCCESS, reservations}
    }

    function failure(error) {
        return {type: reservationsConstants.RESERVATIONS_GET_FAILURE, error}
    }
}
