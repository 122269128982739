import React from 'react';
import {connect} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import {PrivateRoute} from '../../components/PrivateRoute';
import {LoginPage} from '../login/LoginPage';
import '../../scss/style.scss';
import {history} from '../../helpers';

import {alertActions} from '../../actions';


const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const TheLayout = React.lazy(() => import('../../containers/TheLayout'));

class App extends React.Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            dispatch(alertActions.clear());
        });
    }

    render() {
        return (
            <React.Suspense fallback={loading}>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" component={LoginPage}/>
                        <PrivateRoute path="/" name="Home" component={TheLayout}/>
                    </Switch>
                </Router>
            </React.Suspense>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
