import {reservationsConstants} from '../constants';

const initialState = {
    loading: false,
    reservations: null,
    error: null,
};

export function reservations(state = initialState, action) {
    switch (action.type) {
        case reservationsConstants.RESERVATIONS_GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case reservationsConstants.RESERVATIONS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                reservations: action.reservations,
            };
        case reservationsConstants.RESERVATIONS_GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}