import React from 'react'
import {connect} from 'react-redux';
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CInvalidFeedback,
    CRow,
    CSpinner
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {authActions} from '../../actions'

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.props.dispatch(authActions.logout());
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {username, password} = this.state;
        const {dispatch} = this.props;
        if (username && password) {
            dispatch(authActions.login(username, password));
        }
    }

    render() {
        const {loggingIn, error} = this.props;
        const {username, password, submitted} = this.state;
        return (
            <div className="c-app c-default-layout flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol lg="4" md="6">
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm name="login" onSubmit={this.handleSubmit}>
                                            {error &&
                                            <CAlert
                                                fade={true}
                                                show={5}
                                                closeButton
                                                color="danger">Niepoprawne dane logowania</CAlert>
                                            }
                                            <h1>Logowanie</h1>
                                            <p className="text-muted">Zaloguj się do swojego konta</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-user"/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="text" invalid={submitted && !username}
                                                        placeholder="Login" autoComplete="username" name="username"
                                                        value={username} onChange={this.handleChange}/>
                                                {submitted && !username &&
                                                <CInvalidFeedback>Login jest wymagany</CInvalidFeedback>
                                                }
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-lock-locked"/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="password" invalid={submitted && !password}
                                                        placeholder="Hasło" autoComplete="current-password"
                                                        name="password" value={password} onChange={this.handleChange}/>
                                                {submitted && !password &&
                                                <CInvalidFeedback>Hasło jest wymagane</CInvalidFeedback>
                                                }
                                            </CInputGroup>
                                            <CRow>
                                                <CCol xs="6">
                                                    <CButton
                                                        color="primary" className="px-4"
                                                        type="submit" disabled={loggingIn}>
                                                        {loggingIn &&
                                                        <CSpinner size="sm"/>
                                                        }
                                                        {!loggingIn &&
                                                        "Zaloguj się"
                                                        }
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {loggingIn, error} = state.authentication;
    return {
        loggingIn,
        error,
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export {connectedLoginPage as LoginPage};
