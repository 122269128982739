import config from '../config.json';
import {applyAuthHeader, handleResponse, queryParams} from '../helpers';

export const reservationsService = {
    getAll,
    getSingle,
    updateSingle,
    create,
    edit,
    remove
};

function getAll(status, payment, from, to) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
    };
    const params = queryParams({
        'from': from?.format(),
        'to': to?.format(),
        'status': status?.join(','),
        'payment': payment?.join(','),
    });

    return fetch(`${config.apiUrl}/v2/reservations?${params}`, requestOptions)
        .then(handleResponse)
}

function getSingle(id) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
    };

    return fetch(`${config.apiUrl}/v2/reservations/${id}`, requestOptions)
        .then(handleResponse)
}

function updateSingle(id, field, value) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: headers,
    };

    const suffix = value ? 'accept' : 'reject';

    return fetch(`${config.apiUrl}/v2/reservations/${id}/${field}/${suffix}`, requestOptions)
        .then(handleResponse);
}

function create(reservation) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(reservation)
    };

    return fetch(`${config.apiUrl}/v2/reservations`, requestOptions)
        .then(handleResponse);
}

function edit(reservation) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'PATCH',
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(reservation)
    };

    return fetch(`${config.apiUrl}/v2/reservations/${reservation.id}`, requestOptions)
        .then(handleResponse);
}

function remove(id) {
    const headers = {
        'Content-Type': 'application/json'
    }
    applyAuthHeader(headers);
    const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: headers,
    };

    return fetch(`${config.apiUrl}/v2/reservations/${id}`, requestOptions)
        .then(handleResponse);
}