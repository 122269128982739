import {reservationConstants} from '../constants';
import {reservationsService} from '../services';
import {alertActions} from './';
import Moment from "moment";

export const reservationActions = {
    get,
    update,
    create,
    edit,
    remove,
};

function get(id) {
    return dispatch => {
        dispatch(request());

        reservationsService.getSingle(id)
            .then(reservation => {
                reservationsService.getAll(
                    null,
                    null,
                    Moment(reservation.checkInAt).add(1, "days"),
                    Moment(reservation.checkOutAt).subtract(1, "days")
                )
                    .then(data => {
                        const conflicts = data.filter((it) => it.id !== reservation.id && it.payment !== 'REJECTED');
                        dispatch(success(reservation, conflicts));
                    })
                    .catch(error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    });
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationConstants.RESERVATIONS_GET_REQUEST}
    }

    function success(reservation, conflicts) {
        return {type: reservationConstants.RESERVATIONS_GET_SUCCESS, reservation, conflicts}
    }

    function failure(error) {
        return {type: reservationConstants.RESERVATIONS_GET_FAILURE, error}
    }
}

function update(id, field, value) {
    return dispatch => {
        dispatch(request());

        reservationsService.updateSingle(id, field, value)
            .then(data => dispatch(success(data)))
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationConstants.RESERVATIONS_UPDATE_REQUEST}
    }

    function success(reservation) {
        return {type: reservationConstants.RESERVATIONS_UPDATE_SUCCESS, reservation}
    }

    function failure(error) {
        return {type: reservationConstants.RESERVATIONS_UPDATE_FAILURE, error}
    }
}

function create(reservation) {
    return dispatch => {
        dispatch(request());

        reservationsService.create(reservation)
            .then(data => {
                dispatch(success(data));
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationConstants.RESERVATIONS_CREATE_REQUEST}
    }

    function success(reservation) {
        return {type: reservationConstants.RESERVATIONS_CREATE_SUCCESS, reservation}
    }

    function failure(error) {
        return {type: reservationConstants.RESERVATIONS_CREATE_FAILURE, error}
    }
}

function edit(reservation) {
    return dispatch => {
        dispatch(request());

        reservationsService.edit(reservation)
            .then(data => {
                dispatch(success(data));
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationConstants.RESERVATIONS_EDIT_REQUEST}
    }

    function success(reservation) {
        return {type: reservationConstants.RESERVATIONS_EDIT_SUCCESS, reservation}
    }

    function failure(error) {
        return {type: reservationConstants.RESERVATIONS_EDIT_FAILURE, error}
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request());

        reservationsService.remove(id)
            .then(data => {
                dispatch(success());
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            });
    };

    function request() {
        return {type: reservationConstants.RESERVATIONS_REMOVE_REQUEST}
    }

    function success(reservation) {
        return {type: reservationConstants.RESERVATIONS_REMOVE_SUCCESS, reservation}
    }

    function failure(error) {
        return {type: reservationConstants.RESERVATIONS_REMOVE_FAILURE, error}
    }
}
